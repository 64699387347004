export const ROUTES = {
  "/dashboard": "./SamplePage",
  "/group": "./hospital-hierarchy/AddGroupPage",
  "/hospital": "./AddHospitalPage",
  "/generalConfigurations": "./GeneralConfigurations",
  "/department": "./AddDepartmentPage",
  "/subDepartment": "./AddSubDepartmentPage",
  "/createLogin": "./CreateLoginPage",
  "/assignUserRoles": "./UserRolesPage",
  "/mainMenu": "./AddMenuPage",
  "/pagesSetup": "./PagesSetupPage",
  "/roles": "./AddRolesPage",
  "/assignModulesToRoles": "./AssignModulesToRolesPage",
  "/radiologist": "./RadiologyModule/RadiologyPage",
  "/registerEmployee": "./administration/EmployeeRegistrationPage",
  "/registerPatient": "./RegisterPatientPage",
  "/editPatientInfo": "./EditPatientRecordPage",
  "/membershipCard": "./MembershipCard",
  "/patientPrintPrescription": "./PatientPrintPrescriptionPage",
  "/favoritesDiagnosis": "./FavDiagnosisPage",
  "/favoritesMedication": "./FavrtMedPage",
  "/favoritesProcedure": "./FavrtProcPage",
  "/reportingTemplate": "./ReportingTemplatesPage",
  "/setServiceRate": "./administration/ServiceRateSetupPage",
  "/service": "./administration/ServiceSetupPage",
  "/mainGroup": "./administration/ServiceMainGroupPage",
  "/subGroup": "./administration/ServiceSubGroupPage",
  "/favoriteProcedure": "./FavrtProcPage",
  "/templateSetup": "./TemplateSetupPage",
  "/vitals": "./PatientVitalsPage",
  "/triagePatientRegistration": "./TriagePatientRegistrPage",
  "/admissionObservation": "./AdmissionAndObservationPage",
  "/patientHistory": "./PatientHistoryPage",
  "/referrals": "./ReferralsPage",
  "/admissionAdvisedSearch": "./IPDModule/AdmissionAdvisedSearch",
  "/diagnosis": "./PatientDiagnosisPage",
  "/sponsorSetup": "./administration/Panel&Packages/PanelPage",
  "/packageSetup": "./administration/Panel&Packages/PackagePage",
  "/servicestoPackages":
    "./administration/Panel&Packages/AssignServiceToPackage",
  "/investigation": "./PatientInvestigationPage",
  "/favoritesInvestigation": "./FavrtInvestPage",
  "/alertandAllergy": "./AlertsAllergy",
  "/shiftManagement": "./ShiftManagementPage",
  "/setPatientDiscount":
    "./administration/discount-policy/SetPatientDiscountPage",
  "/changeDiscountPolicy":
    "./administration/discount-policy/ChangeDiscountSettingPage",
  "/cashInOutType": "./ManageExpenseMenu/CashInOutTypePage",
  "/cashInOutVoucher": "./ManageExpenseMenu/CashInOutVoucherPage",
  "/dailyCashClosing": "./ManageExpenseMenu/DailyCashClosingPage",
  "/licenseAgreement": "./LicenseAgreementPage",
  "/e-Treatment": "./patient-management/ETreatment",
  "/drDashboard": "./DrDashboardPage",
  "/nurseDashboard": "./NurseDashBoardPage",
  "/templateRetrieval": "./TemplatesRetrievalPage",
  "/drAppointmentScheduling": "./appointments/dr-appointments-scheduling",
  "/AppointmentList": "./appointments/appointment-list",
  "/markDuty": "./MarkDutyPage",
  "/dutySetting": "./DutySettingPage",
  "/wardsRoomsBedsSetup": "./WardsRoomBedSetupPage",
  "/printPrescription": "./PrintPrescriptionPage",
  "/procedures": "./ProceduresPage",
  "/opdPatientBilling": "./PatientBillingPage",
  "/cashBook": "./CashBookPage",
  "/discountApproval": "./DiscountApprovalPage",
  "/refundApproval": "./RefundApprovalPage",
  "/approvedRefunds": "./ApprovedRefundsPage",
  "/processMovement": "./ProcessMovementPage",
  "/dressingRoom": "./DressingRoomPage",
  "/itemRegistration": "./PharmacyModule/ManageProductPage",
  "/managePrescriptions": "./PharmacyModule/ManagePrescriptions",
  "/manageSupplier": "./PharmacyModule/ManageSupplierPage",
  "/manageGrn": "./PharmacyModule/ManageGrnPage",
  "/openingStock": "./PharmacyModule/OpeningStockPage",
  "/manageVoucher": "./PharmacyModule/ManageVoucherPage",
  "/posInvoices": "./PharmacyModule/ManageInvoicesPage",
  "/returnInvoice": "./PharmacyModule/ManageReturnInvoicePage",
  "/fixSaleRate": "./PharmacyModule/ManageFixSaleRatePage",
  "/internalStockDemand": "./ProcurementModule/InternalStockDemandPage",
  "/managePo": "./ProcurementModule/ManagePOPage",
  "/manageIgp": "./ProcurementModule/ManageIGPPage",
  "/aggregateStockDemand": "./ProcurementModule/AggregateStockDemand",
  "/requestForQuotation": "./ProcurementModule/RequestForQuotation",
  "/quotationEvaluation": "./ProcurementModule/QuotationEvaluation",
  "/manageStn": "./ProcurementModule/ManageSTN",
  "/stockInHand": "./Reports/StockInHandPage",
  "/dailySaleReport": "./Reports/DailySaleReport",
  "/nearExpiryMedicine": "./Reports/NearExpiryMedicine",
  "/patientInObservationNurse": "./NurseObservationPage",
  "/patientinObservationDoctor": "./DoctorObservationPage",
  "/deathCertificate": "./DeathCertificatePage",
  "/complaintsAndExamination": "./ComplaintsAndExaminationHistoryPage",
  "/pages": "./AssignPages",
  "/wardRoomBedSetup": "./WardsRoomBedSetupPage",
  "/patientRecords": "./Reports/PatientRecords",
  "/recievedPatient": "./RecievedPatientPage",
  "/emrReport": "./patient-management/EmrReport",
  "/cashExpenseVoucher": "./Reports/CashExpenseVoucher",
  "/newPatientHistory": "./NewPatientHistory",
  "/patientExamination": "./PatientExamination",
  "/doctorTemplate": "./ManageDoctorTemplate",
};
